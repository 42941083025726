/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: '#{$fa-font-family}';
  src: url('#{$fa-font-path}/forkawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/forkawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/forkawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
    url('#{$fa-font-path}/forkawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/forkawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('#{$fa-font-path}/forkawesome-webfont.svg?v=#{$fa-version}#forkawesomeregular') format('svg');
//  src: url('#{$fa-font-path}/ForkAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
