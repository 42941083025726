// Functions
// --------------------------

// Helper function which adds quotes to preserve unicode values in CSS output.
//
// See: https://github.com/sass/sass/issues/1395
// See: https://stackoverflow.com/questions/30421570/sass-unicode-escape-is-not-preserved-in-css-file

@function fa-content($fa-var) {
  @return unquote("\"#{$fa-var}\"");
}
