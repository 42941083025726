// Fonts
$font-family: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Oxygen-Sans,
Ubuntu,
Cantarell,
"Helvetica Neue",
Helvetica,
"游ゴシック",
"PingFang SC",
STXihei,"华文细黑",
"Microsoft YaHei","微软雅黑",
SimSun,"宋体",
Heiti,"黑体",
sans-serif;
$code-font-family: SFMono-Regular,
Consolas,
Liberation Mono,
Menlo,
monospace;

// Colors
$bg-color: #fafafa !default;
$fg-color: #212121 !default;
$alt-bg-color: #e0e0e0 !default;
$alt-fg-color: #000 !default;
$darker-alt-bg-color: #ccc !default;
$link-color: #1565c0 !default;
$cite-bg-color: #fff !default;
$cite-fg-color: #333 !default;
$cite-border-color: #999 !default;
$cite-button-bg-color: #e2e6ea !default;
$cite-button-fg-color: #333 !default;
$button-hover-bg-color: #cad2d8 !default;
$summary-bg-color: #f0f0f0 !default;
$summary-border-color: #1976d2 !default;
$summary-color: $fg-color !default;

// Dark colors
$bg-color-dark: #2e3138 !default;
$fg-color-dark: #dadada !default;
$alt-bg-color-dark: #424242 !default;
$alt-fg-color-dark: #dadada !default;
$lighter-alt-bg-color-dark: #4f4f4f !default;
$link-color-dark: #c9b188 !default;
$cite-bg-color-dark: #212328 !default;
$cite-fg-color-dark: #ececec !default;
$cite-border-color-dark: #ccc !default;
$cite-button-bg-color-dark: #3d4454 !default;
$cite-button-fg-color-dark: #ececec !default;
$button-hover-bg-color-dark: #1d3d5f !default;
$summary-bg-color-dark: #383c44 !default;
$summary-border-color-dark: #b29a5b !default;
$summary-color-dark: $fg-color-dark !default;

// Notice colors
$fg-color-notice-note-icon: #5e35b1 !default;
$bg-color-notice-note-title: #673ab71a !default;
$bg-color-notice-note-content: #7e57c21a !default;
$fg-color-notice-tip-icon: #00897b !default;
$bg-color-notice-tip-title: #0096881a !default;
$bg-color-notice-tip-content: #26a69a1a !default;
$fg-color-notice-example-icon: #6d4c41 !default;
$bg-color-notice-example-title: #7955481a !default;
$bg-color-notice-example-content: #8d6e631a !default;
$fg-color-notice-question-icon: #7cb342 !default;
$bg-color-notice-question-title: #8bc34a1a !default;
$bg-color-notice-question-content: #9ccc651a !default;
$fg-color-notice-info-icon: #1e88e5 !default;
$bg-color-notice-info-title: #2196f31a !default;
$bg-color-notice-info-content: #42a5f51a !default;
$fg-color-notice-warning-icon: #ffb300 !default;
$bg-color-notice-warning-title: #ffc1071a !default;
$bg-color-notice-warning-content: #ffca281a !default;
$fg-color-notice-error-icon: #e53935 !default;
$bg-color-notice-error-title: #f443361a !default;
$bg-color-notice-error-content: #ef53501a !default;
